import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ConfigService } from '@app/core/config.service';
import { LinksService } from '@app/core/links.service';
import { AUTH0_LOGIN_PAGE } from '@app/core/mixpanel.constants';
import { OmguiButtonModule, OmguiButtonVariant } from '@app/omgui/omgui-button/omgui-button.component';
import { OmguiExternalLinkIconModule } from '@app/omgui/omgui-external-link-icon/omgui-external-link-icon.component';
import { ERROR_STATE_DIRECTIVES } from '@app/shared/error-state/error-state.component';
import { CLAIM_CODE_PARAM_KEY } from '@app/shared/hornbill-params';
import { SimpleNavbarComponent } from '@app/shared/simple-navbar/simple-navbar.component';

import { RegistrationAnalyticsService } from '..';

export enum RegistrationErrorCodes {
  PrepaidEnrollmentDefault = 'prepaid_enrollment_default',
  PrepaidEnrollmentInvalidCode = 'prepaid_enrollment_invalid_code',
  PrepaidEnrollmentCodeClaimed = 'prepaid_enrollment_claimed_code',
  LoginWithAmazonEnrollmentDefault = 'login_with_amazon_enrollment_default',
  DuplicateExternalId = 'duplicate_external_id',
  LoggingInWithAmazonWithoutLinkedPatientAccount = 'logging_in_with_amazon_without_linked_patient_account',
}

export enum MembershipGroupInviteValidationErrors {
  NotFound = 'not_found',
  InvalidCode = 'invalid_code',
  RevokedCode = 'revoked_code',
  RejectedCode = 'rejected_code',
  ClaimedCode = 'claimed_code',
}

@Component({
  selector: 'om-registration-error',
  templateUrl: './registration-error.component.html',
  styleUrls: ['./registration-error.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SimpleNavbarComponent,
    OmguiButtonModule,
    OmguiExternalLinkIconModule,
    ...ERROR_STATE_DIRECTIVES,
  ],
})
export class RegistrationErrorComponent implements OnInit {
  errorCode: RegistrationErrorCodes = RegistrationErrorCodes.PrepaidEnrollmentDefault;
  claimCode: string;

  protected manageMembershipOnAmazonUrl: string;

  readonly RegistrationErrorCodes = RegistrationErrorCodes;
  readonly ButtonVariant = OmguiButtonVariant;
  readonly AUTH0_LOGIN_PAGE = AUTH0_LOGIN_PAGE;

  constructor(
    readonly links: LinksService,
    private activatedRoute: ActivatedRoute,
    private registrationAnalyticsService: RegistrationAnalyticsService,
    private config: ConfigService,
  ) {}

  ngOnInit() {
    this.manageMembershipOnAmazonUrl = this.config.json.amazonUrls.manageMembership;

    const routeErrorCode = this.activatedRoute.snapshot.queryParams['errorCode'];
    const routeClaimCode = this.activatedRoute.snapshot.queryParams[CLAIM_CODE_PARAM_KEY];
    const registrationErrorCodes = [
      RegistrationErrorCodes.PrepaidEnrollmentInvalidCode,
      RegistrationErrorCodes.PrepaidEnrollmentCodeClaimed,
      RegistrationErrorCodes.LoginWithAmazonEnrollmentDefault,
      RegistrationErrorCodes.DuplicateExternalId,
      RegistrationErrorCodes.LoggingInWithAmazonWithoutLinkedPatientAccount,
    ];

    if (registrationErrorCodes.includes(routeErrorCode)) {
      this.errorCode = routeErrorCode;
      this.claimCode = routeClaimCode;
    }

    const routeSource = this.activatedRoute.snapshot.queryParams['errorSource'];

    this.registrationAnalyticsService.trackPrepaidEnrollmentDefaultErrorPageView(
      this.errorCode,
      routeClaimCode,
      routeSource,
    );
  }
}
